import React from "react"
import Modal from "./Modal"

export const FirstStepModal = ({open, setOpen}) => {
  return (
      <Modal open={open} setOpen={setOpen}>
        <div className="w-full bg-white p-4">
          <article className='w-full flex flex-col items-center justify-center'>
            <h1 className="font-medium text-center w-full text-[20px]">Приветствуем Вас в приложении Navbat!</h1>
            <p className='mt-4'>Для полной настройки вашего аккаунта необходимо пройти несколько обязательных шагов</p>
            <p className='text-left w-full mt-2'><b>Шаг 1. </b>Заполните все необходимые поля в разделе настроек</p>
          </article>
        </div>
      </Modal>
  )
}

