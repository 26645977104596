import React, { useState } from "react"
import { EditBtn } from "../../../shared/ui/button/EditBtn"
import { TemplateFormModal } from "../analyzes/formPage/TemplateFormModal"
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { TemplateModal } from "../analyzes/formPage/TemplateModal"

export const TemplateRow = ({id, name, tests, price, items, status, data}) => {
  const [active, setActive] = useState(false)
  const [open, setOpen] = useState(false)
  return (
    <div
         className="flex items-center justify-start w-full py-4 border-b border-[#EDEDED] px-7">
      <p className="whitespace-nowrap font-medium text-[#1E3148] text-[14px] mr-8">{id}</p>
      <p
        className="w-full whitespace-nowrap font-medium text-[#1E3148] text-[14px]">{name} {tests} {tests === 1 ? 'показатель' : tests > 1 && tests < 5 ? 'показателя' : 'показателей'}</p>
      <p className="w-full whitespace-nowrap font-medium text-[#1E3148] text-[14px] ml-6">{price}</p>

      <div className="w-full whitespace-nowrap font-medium text-[14px] ml-6">
        <span className={`py-[2px] px-[10px] rounded-[12px]  ${status === 1 ? 'bg-[#D1FAE5] text-[#065F46]' : 'bg-[#FEE2E2] text-[#991B1B]'}`}>{status === 1 ? 'Готов' : 'Не готов'}</span>
        {/*<span className="py-[2px] px-[10px] rounded-[12px] bg-[#D1FAE5] text-[#065F46]">Готов</span>*/}
      </div>
      <p className="whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">
        <div className="flex items-center justify-end gap-1">
          <div className='cursor-pointer hover:text-black transition-all' onClick={() => setOpen(true)}>
            <LocalPrintshopIcon colorr={'disabled'}/>
          </div>
          <EditBtn type={'button'} onClick={() => setActive(true)} />

        </div>
      </p>
      <TemplateModal name={name} data={data} items={items} open={open} setOpen={setOpen}/>
      <TemplateFormModal name={name} items={items} id={id} active={active} setActive={setActive}/>
    </div>
  )
}

