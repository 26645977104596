import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import { getApiConfig } from "shared/api"
import toast from "react-hot-toast"
import {currentUrl} from '../../url.js'

const { accessToken } = getApiConfig()

type Customer = {
  id: number;
  name: string,
  surname: string,
  phone: string,
  email: string | null,
  salon_id: 1,
}

type CustomersState = {
  customers: Customer[];
  loading: boolean;
  error: boolean;
}

export const fetchCustomers = createAsyncThunk<Customer[], string, {rejectValue: boolean}>(
  'customers/fetchCustomers',
  async function(search, {rejectWithValue}) {
    try {
      if(accessToken) {
        const res = await fetch(`${currentUrl}/master/search/clients?keyword=${search}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const {data} = await res.json()

        const processedData:Customer[] = data?.map(item => ({
          id: item.id,
          name: item.name,
          surname: item.surname,
          phone: item.phone,
          email: item?.email,
          salon_id: item.salon_id
        }))
        return processedData
      }
    } catch (err) {
      toast.error('Ошибка при загрузке данных, попробуйте позже', {position: 'bottom-right'})
    }
  }
)

const initialState: CustomersState = {
  customers: [],
  loading: false,
  error: false
}

const customersSlice = createSlice({
  name: 'customersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.customers = action.payload
      })
      .addCase(fetchCustomers.rejected, (state) => {
        state.loading = false
        state.error = true
      })
      // .addCase(searchCustomers.pending, (state) => {
      //   state.loading = true
      //   state.error = false
      // })
      // .addCase(searchCustomers.fulfilled, (state, action) => {
      //   state.loading = false
      //   state.searchCustomers = action.payload
      // })
      // .addCase(searchCustomers.rejected, (state) => {
      //   state.loading = false
      //   state.error = true
      // })

  }
})

export default customersSlice.reducer



