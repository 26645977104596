import { Modal } from "@mui/material"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import { useEffect, useState } from "react"
import { useGetCitiesQuery } from "../../../settings/settingsApi"

function getCurrentDateTime() {
  const now = new Date();

  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
  const year = now.getFullYear();

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
}

const formatRightDate = (date) => {

  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${day}-${month}-${year}`
}

const user = JSON.parse(localStorage.getItem('user'))

export const BillModal = ({ openBill, setOpenBill, templates, formdata, calculatedSum, money, balance, postResponse, data }) => {
  const [cityName, setCityName] = useState('')
  const {data: citiesData} = useGetCitiesQuery()
  console.log(new Date(formdata?.birthday).getFullYear())

  useEffect(() => {
    const cityName = citiesData?.find(item => item.id === user?.salon?.city_id)
    setCityName(cityName?.name)
  }, [user, citiesData])

  const openPdfInNewTab = (pdf) => {
    // Преобразование документа jsPDF в blob
    const pdfBlob = pdf.output('blob');

    // Создание URL для blob-объекта
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Открытие PDF в новой вкладке
    window.open(pdfUrl, '_blank');
  };

  const generatePdf = async () => {
    const content = document.getElementById("bill-content");
    const canvas = await html2canvas(content, {
      scale: 2, // Увеличить качество изображения
      useCORS: true, // Если изображения загружаются с других доменов
    });
    const imgData = canvas.toDataURL("image/png");
    const pdfWidth = canvas.width * 0.5; // Уменьшение размера изображения
    const pdfHeight = canvas.height * 0.5;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [793, pdfHeight]
    });
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width * 0.6, canvas.height * 0.8 ,  undefined, 'FAST');
    openPdfInNewTab(pdf);
  };

  return (
    <Modal open={openBill} onClose={() => setOpenBill(false)}>
      <div  className="bg-white text-black mt-10 mx-auto w-[400px] pb-8 px-1 rounded-[8px]">
        <div id={"bill-content"} className="bg-white mt-6 pb-6 rounded-t-[8px]">
          <div className="w-full mb-4 text-[16px] pt-8">
            <div className='flex items-center justify-center gap-1'>
              <p className="font-bold">"Дента"</p>
              <p className="font-bold">{`г. ${cityName}, ул. ${user?.salon?.street} ${user?.salon?.building}`}</p>
            </div>
            <div className="flex items-center justify-center gap-4">
              <p className="font-bold">{`тел: +992 ${user?.salon?.phone}`}</p>
              <p className="font-bold">{getCurrentDateTime()}</p>
            </div>

            <p className="text-center font-bold">Кассир: {user?.surname} {user?.name[0]}.</p>
          </div>

          <div className="text-center mb-4">
            <p className="text-[16px] font-bold underline">Квитанция №{postResponse?.id || data?.report?.id}</p>
          </div>

          <div className='w-full border-y border-black px-6 py-2 text-black font-semibold'>
            <div className='flex items-start justify-between gap-[40px] w-full'>
              <div className='flex w-full flex-col items-start justify-start'>
                <span className='text-[12px] font-bold'>ID анализа</span>
                <span className='text-[14px] font-bold'>{postResponse?.id || data?.report?.id}</span>
              </div>
              <div className='flex w-full flex-col items-start justify-start'>
                <span className='text-[12px] font-semibold'>ФИО</span>
                <span className='text-[14px] font-semibold'>{postResponse?.surname || formdata.surname} {postResponse?.name || formdata.name} { postResponse?.patronymic || formdata?.patronymic || ''}</span>
              </div>
              <div className='flex w-full flex-col items-start justify-start'>
                <span className='text-[12px] font-semibold'>Пол</span>
                <span className='text-[14px] font-semibold'>{postResponse?.sex || formdata.sex}</span>
              </div>
            </div>
            <div className='flex items-center justify-between gap-[50px] mt-3'>
              <div className='flex flex-col items-start justify-start'>
                <span className='text-[12px]'>Номер телефона</span>
                <span className='text-[14px] font-semibold'>{ postResponse?.phone || formdata.phone}</span>
              </div>
              <div className='flex flex-col items-start justify-start pr-[30px]'>
                <span className='text-[12px]'>Дата рождения</span>
                <span className='text-[14px] font-semibold'>{formatRightDate(postResponse?.birthday) === 'NaN-NaN-NaN' ? formatRightDate(formdata?.birthday) : formatRightDate(postResponse?.birthday)}</span>
              </div>

            </div>
          </div>

          <div className='flex items-center justify-between pr-[100px] mt-[12px] px-6'>
            <p className='text-[12px] font-semibold'>Услуга</p>
            <p className='text-[12px] font-semibold'>Цена</p>
          </div>

          {(data?.templates || templates)?.map(item => <div key={item.id} className="flex items-center justify-between pr-[75px] mt-3 px-6">
            <p className=" text-[14px] font-bold">{item?.name} {item?.tests} {item?.tests === 1 ? 'показатель' : item?.tests > 1  && item?.tests < 5 ? 'показателя' : 'показателей'}</p>
            <p className=" text-[14px] font-bold">{item?.price}</p>
          </div>)}

          <div className='w-full flex items-center justify-between px-6 mt-6'>
            <span className='font-bold text-[16px]'>Итог:</span>
            <span className='font-bold text-[16px]'>{postResponse.total_sum ? `${postResponse.total_sum}.00 TJS` : calculatedSum}</span>
          </div>
          <div className='w-full flex items-center justify-between px-6 mt-2'>
            <span className='text-[16px] font-bold'>Оплачено</span>
            <span className='text-[16px] font-bold'>{money || '0'}.00 TJS</span>
          </div>
          <div className='w-full flex items-center justify-between px-6 mt-2'>
            <span className='text-[16px] font-bold'>Остаток</span>
            <span className='text-[16px] font-bold'>{postResponse.balance ? `${postResponse.balance}.00 TJS` : balance}</span>
          </div>
        </div>
        <div className='flex items-center justify-center mt-3'>
          <button type={'button'} onClick={generatePdf} className='btnFilled'>Распечатать</button>
        </div>

      </div>

    </Modal>


  )
}

