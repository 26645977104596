import React from "react"
import { DataTable as TableTitles } from "../../../shared/ui/dataTable"
import { AnalyzesRows } from "./AnalyzesRows"

const titles = [
  'ID анализа', 'Ф.И.О', 'Дата', 'Номер телефона', 'Сумма', 'Результат', 'Статус оплаты', 'Sms', ''
]

const AnalyzesTable = ({analyzes}) => {
  return (
    <>
      <TableTitles className='sm:grid sm:grid-cols-9 hidden gap-3 whitespace-nowrap' titles={titles} />
      {analyzes?.map((analyze) => (
        <div
          key={analyze?.id}
          className={`grid-cols-9 items-center w-full tableRows sm:grid sm:gap-2`}
        >
          <AnalyzesRows analyze={analyze} />
          <span className='tableRowLine col-span-9'></span>
        </div>
      ))}
      {/*<div>*/}
      {/*  <PaginationBlock*/}
      {/*    setRowsPerPage={bookingsPageStore.setRowsPerPage}*/}
      {/*    setPage={bookingsPageStore.setCurrentPage}*/}
      {/*    currentPage={bookingsPageStore.currentPage}*/}
      {/*    pagesCount={bookingsPageStore.numberOfPages}*/}
      {/*    perPage={bookingsPageStore.rowsPerPage}*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  )
}

export default AnalyzesTable
