import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import { currentUrl } from "../../url"

const accessToken = JSON.stringify(localStorage.getItem('accessToken'))

export const newFeedbackApi = createApi({
  reducerPath: 'newFeedbackApi',
  baseQuery: fetchBaseQuery({
    baseUrl: currentUrl,
    prepareHeaders: (headers) => {
      if(accessToken) {
        headers.set('Authorization', `${accessToken}`)
        headers.set('Accept', 'application/json')
        // headers.set('Content-type', 'x-www-form-urlencoded')
      }
      return headers
    }
  }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation({
      query: (body) => ({
        url: '/master/send-support-email',
        method: 'POST',
        body: body.formData
      })
    })
  })
})

export const {useSendMessageMutation} = newFeedbackApi
