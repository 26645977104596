import { DataTable as TableTitles } from "../../../shared/ui/dataTable"
import { PaginationBlock } from "../../../shared/ui/pagination"
import { TemplateRows } from "./TemplateRows"

const titles = ['Название', "Кол. показателей", "Цена", ""]

export const TemplatesTable = ({templates, templatesRefetch, forceUpdate}) => {



  return (
    <div className='min-h-[300px]'>
      <TableTitles className={"hidden sm:grid"} titles={titles} />
      {templates.map((template) => (
        <div key={template.id} className={`sm:grid grid-cols-4 tableRows`}>
          <TemplateRows key={template.id} templatesRefetch={templatesRefetch} template={template} forceUpdate={forceUpdate} />
          <span className='tableRowLine'></span>
        </div>
      ))}
      {templates.length > 30 && (
        <div>
          <PaginationBlock
            items={templates}
          />
        </div>
      )}
    </div>
  )
}

