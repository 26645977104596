import { InputWithIcon } from "../../../shared/ui/input/InputWithIcon"
import { Search } from "@mui/icons-material"
import { TableSpinner } from "../../../shared/ui/dataTable/spinner"
import { TableFallback } from "../../../widgets/fallbacks/TableFallback"
import { TemplatesTable } from "../../../entities/laboratory/templates/TemplatesTable"
import { AddTemplate } from "../../../features/laboratory/templates/AddTemplate"
import { useState } from "react"
import { useGetTemplatesQuery } from "./templatesApi"
import "react-datepicker/dist/react-datepicker.css"


export const Templates = () => {
  const [active, setActive] = useState(false)
  const [search, setSearch] = useState('')
  const {data: templates, isLoading} = useGetTemplatesQuery({search})
  return (
    <>
      <div className="sm:rounded-lg sm:bg-white p-4">
        <div className="flex mb-4">
          <div className="flex-1 mr-0 sm:mr-6">
            <InputWithIcon
              placeholder={"Поиск"}
              type="text"
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="inputOutlined w-full"
              Icon={<Search fontSize="small" className="text-gray" />}
            />
          </div>
          <button
            onClick={() => setActive(true)}
            className="hidden sm:block btnFilled px-4"
          >
            Добавить шаблон
          </button>
        </div>
        {isLoading ? (
          <div className="w-full min-h-[300px] flex justify-center items-center">
            <TableSpinner />
          </div>
        ) : templates?.data?.length ? (
          <TemplatesTable templates={templates.data} templatesRefetch={() => {}} forceUpdate={() => {}} />
        ) : (
          <TableFallback text={"Список шаблонов пуст"} />
        )}

        <button
          className="block sm:hidden btnAddContent fixed right-4 bottom-[80px] z-10"
        >
          +
        </button>
      </div>
      <AddTemplate active={active} setActive={setActive}/>

    </>
  )
}

