import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth, getApiConfig } from "shared/api"
import { createSlice } from "@reduxjs/toolkit"

const { BASE_URL, prepareHeaders } = getApiConfig()

export const userProfileApi = createApi({
  reducerPath: "userProfileApi",
  tagTypes: "Settings",
  baseQuery: baseQueryWithReauth({baseUrl: BASE_URL, prepareHeaders}),
  endpoints: build => ({
    getMe: build.query({
      query: (params) => params.id && `master/specialists/${params.id}`
    }),
    editProfile: build.mutation({
      query: ({id, ...body}) => ({
        url: `master/specialists/${id}`,
        method: 'PUT',
        body
      })
    })
  })
})

export const {useGetMeQuery, useEditProfileMutation} = userProfileApi

const initialState = {
  settings: []
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSettings (state, action) {
      state.settings = action.payload
    }
  }
})

export const {setSettings} = profileSlice.actions
export default profileSlice.reducer

