import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { currentUrl } from "../../../url"

const token = localStorage.getItem('accessToken')

export const analyzesApi =  createApi({
  reducerPath: 'analyzesApi',
  tagTypes:'Analyzes',
  baseQuery: fetchBaseQuery({
    baseUrl: currentUrl,
    prepareHeaders: headers => {
      headers.set('Accept', 'application/json')
      if(token) {
        headers.set('Authorization', token)
      }
    }
  }),
  endpoints: build => ({
    getAnalyzes: build.query({
      query: (params) => `/master/search/medical-reports?keyword=${params.search || ''}&start_date_from=${params.from || ''}&start_date_to=${params.to || ''}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "Analyzes", id })),
            { type: "Analyzes", id: "LIST" },
          ]
        }
        return [{ type: "Analyzes", id: "LIST" }]
      },
    }),
    getOneAnalyze: build.query({
      query: (params) => `/master/medical-reports/${params.id}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "Analyzes", id })),
            { type: "Analyzes", id: "LIST" },
          ]
        }
        return [{ type: "Analyzes", id: "LIST" }]}
    }),
    createAnalyze: build.mutation({
      query: body => ({
        url: `/master/medical-reports`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: "Analyzes", id: "LIST" }]
    }),
    updateAnalyze: build.mutation({
      query: ({id, ...body}) => ({
        url: `/master/medical-reports/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: "Analyzes", id: "LIST" }]
    }),
    updateTest: build.mutation({
      query: ({id, ...body}) => ({
        url: `/master/medical-tests/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: "Analyzes", id: "LIST" }]
    }),
    sendPdfToSms: build.mutation({
      query: (formData) => ({
        url: `/master/pdf-download`,
        method: 'POST',
        body: formData
      })
    }),
    updateSmsStatus: build.mutation({
      query: (body) => ({
        url: `/master/report-sms`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: "Analyzes", id: "LIST" }]
    }),
    deleteAnalyze: build.mutation({
      query: (id) => ({
        url: `/master/medical-reports/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: "Analyzes", id: "LIST" }]
    })
  })
})

export const {useGetAnalyzesQuery, useCreateAnalyzeMutation, useUpdateAnalyzeMutation, useDeleteAnalyzeMutation, useGetOneAnalyzeQuery, useUpdateTestMutation, useSendPdfToSmsMutation, useUpdateSmsStatusMutation} = analyzesApi
