import React from "react"
import { TemplateForms } from "../../../entities/laboratory/templates/TemplateForms"
import {
  useCreateSubTemplateMutation,
  useEditSubTemplateMutation,
  useEditTemplateMutation,
} from "../../../pages/laboratory/templates/templatesApi"
import { toast } from "react-hot-toast"

export const EditTemplate = ({ setActive, active, template }) => {

  const [editTemplate, { isLoading: templateLoading }] = useEditTemplateMutation()
  const [editTest, { isLoading: testLoading }] = useEditSubTemplateMutation()
  const [createTest, { isLoading: createTestLoading }] = useCreateSubTemplateMutation()

  const onChange = async (data) => {
    console.log(data)
    try {
      await editTemplate({ id: template?.id, name: data?.name, price: data?.price }).unwrap()
      for (const item of data?.analyzes) {
        console.log("id" in item)
        if ("id" in item) {
          try {
            await editTest({
              id: item.id,
              name: item.name,
              norm: item.norm,
              result: 0,
              unit: item.unit,
              medical_template_id: template.id,
            }).unwrap()
          } catch (err) {
            console.log(err)
          }
        } else {
          console.log(item.id)
          try {
            await createTest({
              name: item.name,
              norm: item.norm,
              result: 0,
              unit: item.unit,
              medical_template_id: template.id,
            }).unwrap()
          } catch (e) {
            console.log(e)
          }
        }
      }
      toast.success("Шаблон успешно изменен", { position: "bottom-right" })
      setActive(false)
    } catch (e) {
      console.log(e)
      toast.error("Ошибка при изменении шаблона", { position: "bottom-right" })
      setActive(false)
    }



  }

  return (
    <TemplateForms
      isLoading={templateLoading || testLoading || createTestLoading}
      template={template}
      onSubmit={onChange}
      active={active}
      setActive={setActive} />
  )
}

