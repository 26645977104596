import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { currentUrl } from "../../url"

const accessToken = localStorage.getItem('accessToken')

export const customersApi = createApi({
  reducerPath: 'customersApi',
  tagTypes: 'Customers',
  baseQuery: fetchBaseQuery({
    baseUrl: currentUrl,
    prepareHeaders: (headers) => {
      if(accessToken) {
        headers.set('Authorization', `${accessToken}`)
        headers.set('Accept', 'application/json')
        // headers.set('Content-type', 'x-www-form-urlencoded')
      }
      return headers
    }
  }),
  endpoints: (build) =>( {
    getCustomers: build.query({
      query: () => `/master/clients`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data?.map(({ id }) => ({ type: "Customers", id })),
            { type: "Customers", id: "LIST" },
          ]
        }
        return [{ type: "Customers", id: "LIST" }]
      },
    })
  })
})

export const {useGetCustomersQuery} = customersApi
