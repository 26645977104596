import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import { getApiConfig } from "shared/api"
import toast from "react-hot-toast"
import {currentUrl} from '../../url.js'

const { accessToken } = getApiConfig()

interface passwordData {
  current_password: string;
  password: string;
  password_confirmation: string;
}

interface passwordState {
  loading: boolean;
  error: boolean;
}

export const changePass = createAsyncThunk<any, passwordData, {rejectValue: boolean}>(
  'pass/changePass',
  async function(data, {rejectWithValue}){
    try {
      const res = await fetch(`${currentUrl}/master/update-password`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(data),
      })
      const {message} = await res.json()
      toast.success(`${message}`, {position: "bottom-right"})
      localStorage.removeItem('user')
      localStorage.removeItem('accessToken')
      setTimeout(() => {
        window.location.reload()
      }, 500)
    } catch (err) {
      toast.error(`Вы ввели неверный пароль, попробуйте еще раз`, {position: "bottom-right"})
      rejectWithValue(true)
    }
  }
)

const initialState: passwordState = {
  loading: false,
  error: false,
}

const changePassSlice = createSlice({
  name: 'changePass',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(changePass.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(changePass.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(changePass.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  }
})

export default changePassSlice.reducer
